<template>
  <b-card class="mb-0">
    <b-row>
      <b-col cols="12" lg="6" md="6" sm="12" xs="12"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0" />
      <b-col cols="12" lg="12" md="12" sm="12" xs="12">
        <div class="d-flex align-items-center justify-content-between mb-1">
          <b-col class="d-flex" cols="12" lg="4" md="2" sm="2" xs="4">
            <v-select :options="options" label="pagination" v-model="perPage" />
          </b-col>
          <div class="d-flex align-items-center justify-content-end">
            <b-col cols="12" lg="" md="12" sm="4" xs="12" style="width: 40vh;">
              <v-select variant="primary" placeholder="Choose a project" label="title" :options="projects"
                v-model="selectedProject" @input="onProjectInput" />
            </b-col>
            <b-button variant="outline-primary" @click="clickCreate">
              + Task
            </b-button>
          </div>
        </div>
      </b-col>
    </b-row>


    <b-table id="taskTable" responsive show-empty :items="tasks" :current-page="currentPage" :key="tasks.id"
      :fields="fields" :per-page="perPage">
      <!-- Member Avatar -->
      <template #cell(member)="data">
        <div v-if="data.item.owner">
          <b-avatar class="mr-1" size="40" variant="info">
            <b-img v-if="data.item.owner.avatar" fluid :src="data.item.owner.avatar"></b-img>
            <div v-else>
              {{ data.item.owner.name.substr(0, 1).toUpperCase() }}
            </div>
          </b-avatar>
        </div>
      </template>

      <!-- Projects -->
      <template #cell(projects)="data">
        <b-dropdown no-caret dropdown variant="outline-secondary" size="sm" boundary=".table-container">
          <template #button-content>
            {{ data.item.projects.length }}
          </template>
          <div>
            <b-dropdown-item v-for="p in data.item.projects" :key="data.item.projects.id" @click="redirectProject(p)">
              <span>
                {{ p.title }}
              </span>
            </b-dropdown-item>
          </div>
        </b-dropdown>
      </template>

      <!-- Status -->
      <template #cell(status)="data">
        <b-badge :variant="`light-${categoryStatus(data.item.status)}`">
          {{ resolveStatusText(data.item.status) }}
        </b-badge>
      </template>

      <template #cell(description)="data">
        <div v-html="data.item.description"></div>
      </template>

      <!-- Price -->
      <template #cell(price)="data">
        {{ data.item.price ? data.item.price + "€" : "-" }}
      </template>

      <!-- Actions -->
      <template #cell(actions)="row">
        <b-row>
          <b-col cols="2">
            <b-button class="p-0" v-b-tooltip.hover title="Edit" variant="flat" size="sm" @click="clickEdit(row)">
              <feather-icon icon="EditIcon" />
            </b-button>
          </b-col>
          <b-col cols="2">
            <b-button class="p-0" :disabled="row.item.activities && row.item.activities.length > 0
              " v-b-tooltip.hover title="Delete" variant="flat" size="sm" @click="deleteModal(row)">
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-table>

    <!-- Pagination -->
    <b-row align-h="end">
      <b-col md="12" class="d-flex align-items-center justify-content-end">
        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
          aria-controls="taskTable"></b-pagination>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BFormGroup,
  BCardTitle,
  BTable,
  BCard,
  BButton,
  BPagination,
  BRow,
  BCol,
  BImg,
  BBadge,
  BDropdown,
  BDropdownItem,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { taskHeader } from "@/utils/headers";
import { categoryStatus, resolveStatusText } from "@/utils/status";

export default {
  components: {
    BFormGroup,
    BCardTitle,
    BCard,
    BTable,
    BButton,
    BRow,
    BCol,
    BImg,
    BBadge,
    BPagination,
    vSelect,
    BDropdown,
    BDropdownItem,
    VBTooltip,
    BTooltip,
  },

  directives: {
    "b-tooltip": VBTooltip,
  },

  props: {
    tasks: {
      type: Array,
      required: true,
    },
    project: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      fields: taskHeader,
      users: [],
      selectedProject: null,
      search: "",
      perPage: 10,
      currentPage: 1,
      options: [
        10, 50, 100
      ],
    };
  },
  async created() {
    await this.refetchData();
  },
  computed: {
    projects() {
      return this.$store.state.projectConfig.projects;
    },

    filteredUsers() {
      return this.users.filter((user) => {
        return (
          user.name.toLowerCase().includes(this.search.toLowerCase()) ||
          user.email.toLowerCase().includes(this.search.toLowerCase()) ||
          user.roles[0].name.toLowerCase().includes(this.search.toLowerCase())
        );
      })
        .filter(user => user.id !== this.currentUser.id);
    },
    rows() {
      return this.tasks.length
    }
  },
  methods: {
    async refetchData() {
      await this.$store.dispatch("getAllTaskTemplate")
      await this.$store.dispatch("getProjects");
    },
    async onProjectInput() {
      if (!this.selectedProject) {
        await this.refetchData()
        return;
      }

      const tasks =
        this.projects.find((project) => project.id === this.selectedProject.id)
          ?.tasks_template || [];
      this.$store.dispatch("setTasks", tasks);
    },
    clickCreate() {
      if (this.project) {
        const encodedNextRoute = encodeURIComponent(`/projects/${this.project}`);
        this.$router.push(`/tasks/create?p=${this.project}&next=${encodedNextRoute}`);
        return
      }
      this.$router.push('/tasks/create?type=taskTemplate');
    },

    categoryStatus(categ) {
      return categoryStatus(categ);
    },

    resolveStatusText(categ) {
      return resolveStatusText(categ);
    },

    redirectProject(project) {
      const id = project.id;
      this.$router.push('/projects/' + id);
    },

    clickEdit(row) {
      if (this.project) {
        const encodedNextRoute = encodeURIComponent(
          `/projects/${this.project}`
        );
        this.$router.push(
          `/tasks/${row.item.id}/update?next=${encodedNextRoute}`
        );
        return;
      }
      this.$router.push(`/tasks/${row.item.id}/update?type=taskTemplate`);
    },

    async deleteTasks(id) {
      await this.$store.dispatch('deleteTaskTemplate', id);
      this.$emit('refetch');
      this.selectedProject = null;
    },

    deleteModal(row) {
      this.row = "";
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete that row.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.deleteTasks(row.item.id);
          }
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.clickable-row:hover {
  cursor: pointer;
  background-color: #f5f5f5;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
