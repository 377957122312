<template>
  <TasksTemplateTable :tasks="tasksTemplate" @refetch="refetchData()" />
</template>

<script>
import TasksTemplateTable from "@/components/atoms/tasks/TasksTemplateTable.vue";

export default {
  components: {
    TasksTemplateTable
  },

  computed: {
    tasksTemplate() {
      return this.$store.state.taskTemplateConfig.tasksTemplate;
    },
  },

  async created() {
    await this.refetchData();
  },

  methods: {
    async refetchData() {
      await Promise.all([this.$store.dispatch("getAllProjects"),
        this.$store.dispatch("getAllTaskTemplate")]);
    },
  },
};
</script>
